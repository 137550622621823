<template>
	<layout>
		<div class="padding_20 center bottom_link fs_24">职路数字平台登录</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
			<el-form-item label="" prop="phone">
				<el-input placeholder="请输入手机号码" autocomplete="on" name="phone" class="flex_c" maxlength="11" type="number" v-model.trim="ruleForm.phone">
					<template #prefix>
						<img src="@/assets/imgs/icon/mobile-icon.png" class="icon" alt="">
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="" prop="captcha">
				<el-input placeholder="请输入验证码" autocomplete="on" maxlength="6" type="number" v-model.trim="ruleForm.captcha" @keyup.enter="submit">
					<template #prefix>
						<img src="@/assets/imgs/icon/pas-icon.png" class="icon" alt="">
					</template>
					<template #suffix>
						<el-button type="text" class="btn" v-if="!sandSuccess" :loading="sandLoading" @click="sendCode">发送验证码</el-button>
						<el-button type="text" class="btn" v-else>{{sandTime}}s后重新获取</el-button>
					</template>
				</el-input>
			</el-form-item>
			<el-button type="primary" class="fs_16 loginer" :loading="loading" @click="submit">登 录</el-button>
			<div class="mt_20 flex_c">
				<el-checkbox v-model="checked" size="medium"><span class="fs_14 color_999">同意</span></el-checkbox>
				<a href="/agreement" target="_balck" class="fs_14 xieyi">《职路数字平台用户服务协议》</a>
			</div>
			<!-- <div class="flex_cen fs_14 color_999">
				<div class="flex_c pointer padding_20 mt_10" @click="wechatLogin">
					<img src="@/assets/imgs/icon/wx-icon.png" class="wechat" alt="">
					微信扫码登录
				</div>
			</div> -->
		</el-form>
	</layout>
</template>

<script>
import layout from './layout/main'
import { setToken, s_setItem } from '@/common/cache'
import { isPhone } from '@/common/validate'
import { fetchQuitUrge, fetchCompanyStatus } from '@/common/scheduledTask'
var checkCode = (rule, value, callback) => {
	if (value === '') {
		callback(new Error('请输入验证码'))
	} else if (value.length < 6) {
		callback(new Error('请输入6位数验证码'))
	} else {
		callback()
	}
}
export default {
	name: 'Login',
	components: { layout },
	data() {
		var checkMobile = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入手机号码'))
			} else if (!isPhone(value)) {
				callback(new Error('请输入正确的手机号码'))
			} else {
				callback()
			}
		}
		return {
			ruleForm: {
				phone: '',
				captcha: ''
			},
			checked: true,
			sandSuccess: false,
			loading: false,
			sandLoading: false,
			sandTime: 60,
			rules: {
				phone: [
					{ validator: checkMobile, trigger: 'blur' }
				],
				captcha: [/* 动态效验 */]
			}
		}
	},

	methods: {
		sendCode() {
			this.rules.captcha = []
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sandLoading = true
					this.$axios({
						url: '/api/v2/account/verify?type=login',
						method: 'POST',
						data: {
							phone: this.ruleForm.phone,
							type: 'login'
						},
						no_carryToken: true,
						hideLoading: true,
						shake: true
					}).then(res => {
						setTimeout(() => {
							this.sandLoading = false
						}, 900)
						if(res.code === 200) {
							this.$message.success(res.msg)
							this.sandSuccess = true
							this.countTime()
							// this.rules.captcha.push({ validator: checkCode, trigger: 'blur' })
						}
					})
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		submit() {
			this.rules.captcha.push({ validator: checkCode, trigger: 'blur' })
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					if(!this.checked) {
						this.$message.warning('请勾选 职路数字平台用户服务协议')
						return
					}
					this.loading = true
					this.$axios({
						url: '/api/v2/account/mobile',
						method: 'POST',
						data: this.ruleForm,
						no_carryToken: true,
						hideLoading: true,
						shake: true
					}).then(res => {
						if(res.code === 200) {
							setToken(res.data.token)
							s_setItem('showTost', false)
							setTimeout(()=>{
								fetchQuitUrge()
								fetchCompanyStatus()
								this.$router.replace('/')
							}, 500)
						}else {
							setTimeout(() => {
								this.loading = false
							}, 900)
						}
					})
				}
			})
		},
		wechatLogin() {
			this.$router.push('/bind')
		},
		countTime() {
			var currentTime = 60
			var interval = setInterval(() => {
				this.sandTime = currentTime--
				if (currentTime < 0) {
					clearInterval(interval) // 清除计时器
					this.rules.captcha = []
					this.sandSuccess = false
					this.sandTime = 60
				}
			}, 1000)
		}
	}
}
</script>

<style lang='less' scoped>
@import url("../../assets/style/login.less");
</style>